import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Divider } from '@mui/material';
import { Helmet } from 'react-helmet';

const Services = () => {
  return (
    <>
    <Helmet>
        <title>Clean Slate Detailing - Services</title>
        <meta name="description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta name="keywords" content="car detailing, car cleaning, auto detailing, vehicle detailing, paint correction, professional car cleaning, affordable car detailing, Canonsburg car detailing, ceramic coating" />
        <meta name="author" content="Clean Slate Detailing" />
        <meta property="og:title" content="Clean Slate Detailing - Services" />
        <meta property="og:description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta property="og:url" content="https://www.cleanslateautodetailing.com/services" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Clean Slate Detailing" />
      </Helmet>
    <Container maxWidth="lg" style={{ backgroundColor: '#ffffff', color: '#2797E8', padding: '20px', marginTop: 10 }}>
      <Box id="top" sx={{ my: 8, mt: 0 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Our Detailing Services
        </Typography>
        
       {/* Full Packages */}
       <Grid container spacing={4} id="full-packages">
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Full Details
            </Typography>
             {/* Divider Section */}
            <Box sx={{ my: 4 }}>
              <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '90%', borderBottomWidth: '2px', opacity: 0.6 }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #cd7f32', minHeight: '450px', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)' }}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#cd7f32' }}>Bronze Package</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $149</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body1" style={{ color: '#cd7f32' }}>Exterior</Typography>
                <Typography variant="body2">- Hand-Wash</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Moderate Bug and Tar Removal</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Wheel Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Doorjamb Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Tire Dressing</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Carnuba Wax Application</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Window & Mirror Cleaning</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body1" style={{ color: '#cd7f32' }}>Interior</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Interior & Trunk Vacuuming</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Interior Wipedown (No Heavy Cleaning/Scrubbing)</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Window and Mirror Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Leather Seat Cleaning & Conditioning</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #ffd700', minHeight: '450px', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)'}}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#ffd700' }}>Gold Package</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $199</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body1" style={{ color: '#ffd700' }}>Exterior</Typography>
                <Typography variant="body2" style={{ color: '#ffd700' }}>Includes Bronze Package +</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Clay Bar Paint Decontamination</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Iron Paint Decontamination</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Tire & Trim Dressing</Typography>
                <Divider sx={{ my: 4.75 }} />
                
                <Typography variant="body1" style={{ color: '#ffd700' }}>Interior</Typography>
                <Typography variant="body2" style={{ color: '#ffd700' }}>Includes Bronze Package +</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Interior Scrubbing</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Shampoo & Steam Cleaning </Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Floor Mat Dressing on Request</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #8A9BA8', minHeight: '450px', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)' }}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#8A9BA8' }}>Platinum Package</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $299</Typography>
                <Divider sx={{ my: 1 }} />
                
                <Typography variant="body1" style={{ color: '#8A9BA8' }}>Exterior</Typography>
                <Typography variant="body2" style={{ color: '#8A9BA8' }}>Includes Gold Package +</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Engine Bay Cleaning </Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Light Scratches & Swirls Machine Buffed</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Ceramic Wax Applied by Hand</Typography>
                <Divider sx={{ my: 3.4}} />
                
                <Typography variant="body1" style={{ color: '#8A9BA8' }}>Interior</Typography>
                <Typography variant="body2" style={{ color: '#8A9BA8' }}>Includes Gold Package +</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Headliner Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Full Carpet Extraction</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #00bcd4', minHeight: '450px' , boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)'}}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#00bcd4' }}>Diamond Package</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $499</Typography>
                <Divider sx={{ my: 1 }} />
                
                <Typography variant="body1" style={{ color: '#00bcd4' }}>Exterior</Typography>
                <Typography variant="body2" style={{ color: '#00bcd4' }}>Includes Platinum Package +</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Full Bug and Tar Removal</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Full Paint Correction</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Ceramic Coating (2 Year Protection)</Typography>
                <Divider sx={{ my: 3.25 }} />
                <Typography variant="body1" style={{ color: '#00bcd4' }}>Interior</Typography>
                <Typography variant="body2" style={{ color: '#00bcd4' }}>Includes Platinum Package +</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Application of Ceramic Protectant</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Interior Packages */}
        <Grid container spacing={4} id="interior" sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Interior
            </Typography>
             {/* Divider Section */}
        <Box sx={{ my: 4 }}>
          <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '90%', borderBottomWidth: '2px', opacity: 0.6 }} />
        </Box>

          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #ffd700', minHeight: '250px' , boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)'}}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#ffd700' }}>Interior Value Package</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $149</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Interior & Trunk Vaccuming</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Interior Wipedown (Heavy Cleaning/Scrubbing)</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Window and Mirror Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Spot Stain Treatment</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Shampoo & Steam Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Clean Interior Windows and Mirrors</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Leather Seat Cleaning & Conditioning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Floor Mat Dressing on Request</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        

        {/* Exterior Packages */}
        <Grid container spacing={4} id="exterior" sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Exterior
            </Typography>
             {/* Divider Section */}
        <Box sx={{ my: 4 }}>
          <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '90%', borderBottomWidth: '2px', opacity: 0.6 }} />
        </Box>

          </Grid>


          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #ffd700', minHeight: '250px' , boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)'}}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#ffd700' }}>Exterior Value Package</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $149</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Hand-Wash</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Moderate Bug and Tar Removal</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Wheel Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Window and Mirror Cleaning </Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Clay Bar Paint Decontamination</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Iron Paint Decontamination</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Doorjamb Cleaning</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Tire & Trim Dressing</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Carnuba Wax Application</Typography>

              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Extra Services */}
        <Grid container spacing={4} id="extra" sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Additional Services
            </Typography>
             {/* Divider Section */}
        <Box sx={{ my: 4 }}>
          <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '90%', borderBottomWidth: '2px', opacity: 0.6 }} />
        </Box>

          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #2797E8', minHeight: '180px' , boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)'}}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#2797E8' }}>Paint Correction</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $299</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Removal of water spots, paint over spray, scratches, and more</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Surface leveling and improvement of gloss</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Restores the original color and shine of your vehicle</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Protects your car’s paint from further damage</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ backgroundColor: '#1a1a1a', color: '#fff', border: '3px solid #2797E8', minHeight: '180px' , boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)'}}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#2797E8' }}>Headlight Restoration</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>Starting at $75</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Restore clarity and brightness to headlights</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Remove oxidation and environmental damage</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Improve night driving visibility</Typography>
                <Typography variant="body2" sx={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>- Prolong the lifespan of your headlights</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
    </>
  );
};

export default Services;
