import React from 'react';
import { Container, Box } from '@mui/material';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <>
    <Helmet>
        <title>Clean Slate Detailing - Contact</title>
        <meta name="description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta name="keywords" content="car detailing, car cleaning, auto detailing, vehicle detailing, paint correction, professional car cleaning, affordable car detailing, Canonsburg car detailing, ceramic coating" />
        <meta name="author" content="Clean Slate Detailing" />
        <meta property="og:title" content="Clean Slate Detailing - Contact" />
        <meta property="og:description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta property="og:url" content="https://www.cleanslateautodetailing.com/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Clean Slate Detailing" />
      </Helmet>
    <Container maxWidth="md">
      <Box id="top" mt={0}>
        <ContactForm />
      </Box>
    </Container>
  </>
  );
};

export default Contact;
