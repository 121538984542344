import { Container, Typography, Box, Grid, Card, CardContent, CardMedia, Button, Divider } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import { useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100; // Adjust this value as needed
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const basePath = '/images';

  const heroImage = isMobile
    ? `${basePath}/hero/mobile/mobile1.webp`
    : `${basePath}/hero/desktop/desktop1.webp`;

  const exteriorImage = `${basePath}/exterior/exterior1.webp`;
  const interiorImage = `${basePath}/interior/interior1.webp`;
  const specialImage = `${basePath}/package/package1.webp`;

  return (
    <>
    <Helmet>
        <title>Clean Slate Detailing - Home</title>
        <meta name="description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta name="keywords" content="car detailing, car cleaning, auto detailing, vehicle detailing, paint correction, professional car cleaning, affordable car detailing, Canonsburg car detailing, ceramic coating" />
        <meta name="author" content="Clean Slate Detailing" />
        <meta property="og:title" content="Clean Slate Detailing - Home" />
        <meta property="og:description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta property="og:url" content="https://www.cleanslateautodetailing.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Clean Slate Detailing" />
      </Helmet>
    <Container maxWidth={false} disableGutters sx={{ mt: 1 }}>
      <Box sx={{ backgroundColor: '#fff', color: '#000', padding: '20px' }}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: isMobile ? '90vh' : '85vh',
            textAlign: 'center',
            backgroundImage: `url(${heroImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: '#fff',
            padding: '20px',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)'
          }}
        >
          <Box
            sx={{
              position: 'relative',
              zIndex: 1,
              background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
              padding: '20px',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h2" gutterBottom>
              Welcome to CleanSlate Detailing
            </Typography>
            <Typography variant="h5" gutterBottom>
              Starting Fresh with Every Detail
            </Typography>
            <Button variant="contained" sx={{ backgroundColor: '#2797E8', mt: 4 }} size="large" component={Link} to="/about">
              Learn More
            </Button>
          </Box>
        </Box>
        <Box sx={{ my: 4 }}>
          <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '80%', borderBottomWidth: '2px', transition: 'opacity 2s', ':hover': { opacity: 0.5 } }} />
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#2797E8' }}>
            Our Services
          </Typography>
          <Grid container spacing={4}>
            {[
              { link: '/services#exterior', image: exteriorImage, title: 'Exterior Detailing', description: 'Reveal your vehicle\'s best look with our premium exterior detailing. Enjoy a flawless finish and unmatched shine, enhancing both its aesthetic appeal and value. Protect your investment with our expert care.' },
              { link: '/services#interior', image: interiorImage, title: 'Interior Detailing', description: 'Experience a fresh, spotless interior. Enjoy a revitalized space that provides comfort and cleanliness for every ride.' },
              { link: '/services#full-packages', image: specialImage, title: 'Packages', description: 'Discover our comprehensive packages designed to meet all your detailing needs. Each package provides thorough care, transforming your vehicle into a pristine, showroom-ready condition.' }
            ].map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card component={Link} to={service.link} scroll={el => scrollWithOffset(el)} sx={{ backgroundColor: '#fff', color: '#000', textAlign: 'center', textDecoration: 'none', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)' }}>
                  <CardMedia
                    component="img"
                    height="320"
                    image={service.image}
                    alt={service.title}
                    sx={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h5" component="div" sx={{ color: '#2797E8', mt: 2 }}>
                      {service.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ my: 4 }}>
          <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '80%', borderBottomWidth: '2px', transition: 'opacity 2s', ':hover': { opacity: 0.5 } }} />
        </Box>
        <Box sx={{ my: 6, textAlign: 'center' }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Box sx={{ textAlign: 'center' }}>
                <img src={`${basePath}/logos/logoFullSize.png`} alt="CleanSlate Detailing Logo" style={{ width: '100%', maxWidth: '450px', marginBottom: '20px' }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="h3" gutterBottom sx={{ color: '#2797E8' }}>
                  About Us
                </Typography>
                <Typography variant="body1" paragraph>
                  At CleanSlate Detailing, we take pride in transforming your vehicle to a pristine condition. Our passion for cars is reflected in every detail we perform, ensuring that you get the best service possible. Whether it's a full detailing package or specialized services, our commitment to quality and customer satisfaction is unmatched.
                </Typography>
                <Typography variant="body1" paragraph>
                  Our goal is to provide you with the best possible service, leaving your vehicle looking as good as new.
                </Typography>
                <Typography variant="body1" paragraph>
                  Discover the difference with CleanSlate Detailing.
                </Typography>
                <Button variant="contained" sx={{ backgroundColor: '#2797E8', mt: 2 }} size="large" component={Link} to="/services#top">
                  Explore Our Services
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 3 }}>
          <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '80%', borderBottomWidth: '2px', transition: 'opacity 2s', ':hover': { opacity: 0.5 } }} />
        </Box>
        <Box sx={{ my: 6, textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom sx={{ color: '#2797E8' }}>
            Get in Touch
          </Typography>
          <Typography variant="body1" gutterBottom>
            Ready to give your car a clean slate? Contact us today!
          </Typography>
          <Button variant="contained" sx={{ backgroundColor: '#2797E8', mt: 2 }} size="large" component={Link} to="/contact#top" scroll={el => scrollWithOffset(el)}>
            Contact Us
          </Button>
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default Home;
