import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button } from '@mui/material';
import { Helmet } from 'react-helmet';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    email: false,
    phone: false,
    message: false
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?[0-9]{10,15}$/;
    return phoneRegex.test(phone) || phone === ''; // Allow empty phone
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    if (name === 'email') {
      isValid = validateEmail(value);
    } else if (name === 'phone') {
      isValid = validatePhone(value);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !isValid
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isEmailValid = validateEmail(formData.email);
    const isPhoneValid = validatePhone(formData.phone);
    const isMessageValid = formData.message !== '';

    if (!isEmailValid && !isPhoneValid || !isMessageValid) {
      setErrors({
        email: !isEmailValid,
        phone: !isPhoneValid,
        message: !isMessageValid
      });
      setLoading(false);
      return;
    }

    setErrors({
      email: false,
      phone: false,
      message: false
    });

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    if (response.ok) {
      alert('Message sent successfully! We will get back to you as soon as possible.');
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } else {
      alert('Failed to send message. Please try again later.');
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="lg" style={{ color: '#2797E8', padding: '20px' }} sx={{ mt: 1.4 }}>
      <Box sx={{ my: 8, mt: 0 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
            error={errors.email}
            helperText={errors.email && "Please enter a valid email address."}
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
            error={errors.phone}
            helperText={errors.phone && "Please enter a valid phone number."}
          />
          <TextField
            fullWidth
            label="Message"
            name="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
            error={errors.message}
            helperText={errors.message && "Message is required."}
          />
          <Button type="submit" variant="contained" sx={{ backgroundColor: '#2797E8', mt: 4 }} size="large" disabled={loading}>
            {loading ? 'Sending...' : 'Send Message'}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default ContactForm;
