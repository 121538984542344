import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <>
      <Helmet>
        <title>Clean Slate Detailing - About Us</title>
        <meta name="description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta name="keywords" content="car detailing, car cleaning, auto detailing, vehicle detailing, paint correction, professional car cleaning, affordable car detailing, Canonsburg car detailing, ceramic coating" />
        <meta name="author" content="Clean Slate Detailing" />
        <meta property="og:title" content="Clean Slate Detailing - About Us" />
        <meta property="og:description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta property="og:url" content="https://www.cleanslateautodetailing.com/about" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Clean Slate Detailing" />
      </Helmet>
    <Container maxWidth="md" sx={{ mt: 3.82 }}>
      <Box sx={{ textAlign: 'center', mb: 4, mt: 0 }}>
        <Typography variant="h3" gutterBottom style={{color: '#2797E8'}}>
          About CleanSlate Detailing
        </Typography>
        <Typography variant="h5" gutterBottom style={{color: '#2797E8'}}>
          Starting Fresh with Every Detail
        </Typography>
                {/* Divider Section */}
        <Box sx={{ my: 4 }}>
          <Divider sx={{ borderColor: '#2797E8', mx: 'auto', width: '80%', borderBottomWidth: '2px', transition: 'opacity 2s', ':hover': { opacity: 0.5 } }} />
        </Box>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom style={{color: '#2797E8'}}>
          Our Story
        </Typography>
        <Typography variant="body1" paragraph>
          CleanSlate Detailing was born from a deep passion for cars and a dedication to maintaining their beauty. My journey in car detailing began as a way to keep my vehicle in pristine condition without the high costs of professional services. Over time, I honed my skills and transformed this passion into a business that offers top-notch car detailing services.
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom style={{color: '#2797E8'}}>
          Our Mission
        </Typography>
        <Typography variant="body1" paragraph>
          At CleanSlate Detailing, our mission is to offer our customers a true clean slate for their vehicles, enabling them to maintain their car's cleanliness with ease. We focus on quality, taking the time needed to ensure each detail is performed to the highest standards.
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom style={{color: '#2797E8'}}>
          Our Services
        </Typography>
        <Typography variant="body1" paragraph>
          We specialize in a range of services to meet all your car detailing needs:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Ceramic Coatings</Typography>
          </li>
          <li>
            <Typography variant="body1">Paint Correction</Typography>
          </li>
          <li>
            <Typography variant="body1">Steam Cleaning</Typography>
          </li>
          <li>
            <Typography variant="body1">Carpet Extraction</Typography>
          </li>
          <li>
            <Typography variant="body1">Headlight Restoration</Typography>
          </li>
          <li>
            <Typography variant="body1">And more!</Typography>
          </li>
        </ul>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom style={{color: '#2797E8'}}>
          Our Commitment
        </Typography>
        <Typography variant="body1" paragraph>
          As the sole member of CleanSlate Detailing, I personally guarantee the quality of every job. By operating out of my garage, I can offer competitive pricing without the overhead costs of a typical shop, ensuring you receive exceptional value for your money.
        </Typography>
      </Box>
    </Container>
    </>
  );
};

export default About;
