import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, IconButton, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Helmet } from 'react-helmet';

const services = [
  "Bronze Package",
  "Gold Package",
  "Platinum Package",
  "Diamond Package",
  "Exterior Value Package",
  "Interior Value Package",
  "Paint Correction",
  "Headlight Restoration"
];

const Booking = () => {
  const [formData, setFormData] = useState({
    cars: [{ make: '', model: '', year: '', key: Date.now() }],
    services: [{ type: '', key: Date.now() }],
    email: '',
    phone: '',
    notes: ''
  });

  const [errors, setErrors] = useState({
    email: false,
    phone: false,
    notes: false
  });

  const [loading, setLoading] = useState(false);

  const handleCarChange = (index, event) => {
    const updatedCars = formData.cars.map((car, i) =>
      i === index ? { ...car, [event.target.name]: event.target.value } : car
    );
    setFormData({ ...formData, cars: updatedCars });
  };

  const handleServiceChange = (index, event) => {
    const updatedServices = formData.services.map((service, i) =>
      i === index ? { ...service, type: event.target.value } : service
    );
    setFormData({ ...formData, services: updatedServices });
  };

  const addService = () => {
    if (formData.services.length >= 4) {
      alert('You can only add up to 4 services.');
      return;
    }
    setFormData({ ...formData, services: [...formData.services, { type: '', key: Date.now() }] });
  };

  const removeService = (key) => {
    setFormData({ ...formData, services: formData.services.filter(service => service.key !== key) });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?[0-9]{10,15}$/;
    return phoneRegex.test(phone) || phone === ''; // Allow empty phone
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    if (name === 'email') {
      isValid = validateEmail(value);
    } else if (name === 'phone') {
      isValid = validatePhone(value);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !isValid
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isEmailValid = validateEmail(formData.email);
    const isPhoneValid = validatePhone(formData.phone);

    if (!isEmailValid && !isPhoneValid) {
      setErrors({
        email: !isEmailValid,
        phone: !isPhoneValid,
        notes: !formData.notes
      });
      setLoading(false);
      return;
    }

    setErrors({
      email: false,
      phone: false,
      notes: !formData.notes
    });

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    if (response.ok) {
      alert('Booking request sent successfully! We will get in touch soon to schedule your appointment.');
      setFormData({
        cars: [{ make: '', model: '', year: '', key: Date.now() }],
        services: [{ type: '', key: Date.now() }],
        email: '',
        phone: '',
        notes: ''
      });
    } else {
      alert('Failed to send booking request.');
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
    <Helmet>
        <title>Clean Slate Detailing - Booking</title>
        <meta name="description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta name="keywords" content="car detailing, car cleaning, auto detailing, vehicle detailing, paint correction, professional car cleaning, affordable car detailing, Canonsburg car detailing, ceramic coating" />
        <meta name="author" content="Clean Slate Detailing" />
        <meta property="og:title" content="Clean Slate Detailing - Booking" />
        <meta property="og:description" content="Clean Slate Detailing offers professional and affordable car detailing services in Canonsburg, PA. Starting Fresh with every Detail." />
        <meta property="og:url" content="https://www.cleanslateautodetailing.com/booking" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Clean Slate Detailing" />
      </Helmet>
    <Container maxWidth="md" style={{ backgroundColor: '#fff', color: '#000', padding: '20px', marginTop: 10 }}>
      <Box sx={{ my: 8, mt: 0 }}>
        <Typography variant="h3" align="center" gutterBottom style={{ color: '#2797E8' }}>
          Book a Service
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Please fill out the form below. We will get in touch soon to schedule your appointment.
        </Typography>
        <form onSubmit={handleSubmit}>
          {formData.cars.map((car, index) => (
            <Box key={car.key} sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Make"
                name="make"
                value={car.make}
                onChange={event => handleCarChange(index, event)}
                margin="normal"
                variant="outlined"
                style={{ backgroundColor: '#fff' }}
              />
              <TextField
                fullWidth
                label="Model"
                name="model"
                value={car.model}
                onChange={event => handleCarChange(index, event)}
                margin="normal"
                variant="outlined"
                style={{ backgroundColor: '#fff' }}
              />
              <TextField
                fullWidth
                label="Year"
                name="year"
                value={car.year}
                onChange={event => handleCarChange(index, event)}
                margin="normal"
                variant="outlined"
                style={{ backgroundColor: '#fff' }}
              />
            </Box>
          ))}
          {formData.services.map((service, index) => (
            <Box key={service.key} display="flex" alignItems="center" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                select
                label="Service Package"
                name="servicePackage"
                value={service.type}
                onChange={event => handleServiceChange(index, event)}
                margin="normal"
                variant="outlined"
                style={{ backgroundColor: '#fff', marginRight: 8 }}
              >
                {services.map(serviceType => (
                  <MenuItem key={serviceType} value={serviceType}>{serviceType}</MenuItem>
                ))}
              </TextField>
              {formData.services.length > 1 && (
                <IconButton onClick={() => removeService(service.key)}>
                  <RemoveIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <Button onClick={addService} startIcon={<AddIcon />} variant="outlined" color="primary" sx={{ mt: 2 }}>
            Add Another Service
          </Button>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
            error={errors.email}
            helperText={errors.email && "Please enter a valid email address."}
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
            error={errors.phone}
            helperText={errors.phone && "Please enter a valid phone number."}
          />
          <TextField
            fullWidth
            label="Additional Notes"
            name="notes"
            multiline
            rows={4}
            value={formData.notes}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
            error={errors.notes}
            helperText={errors.notes && "Notes are required."}
          />
          <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            * To book multiple vehicles, please complete a form for each separately 
          </Typography>
          <Button type="submit" variant="contained" sx={{ backgroundColor: '#2797E8', mt: 4 }} size="large" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Booking'}
          </Button>
        </form>
      </Box>
    </Container>
    </>
  );
};

export default Booking;
