import React from 'react';
import { Box, Typography, Link, Container, Grid } from '@mui/material';
import { Phone, Facebook, Instagram } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#2797E8', color: '#fff', py: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" textAlign="center">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              CleanSlate Detailing
            </Typography>
            <Typography variant="body1">
              <Link href="tel:724-470-3464" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Phone sx={{ verticalAlign: 'middle', mr: 1 }} /> 724-470-3464
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Link href="/" color="inherit" underline="none" sx={{ display: 'block', mb: 0.5 }}>Home</Link>
            <Link href="/services" color="inherit" underline="none" sx={{ display: 'block', mb: 0.5 }}>Services</Link>
            <Link href="/about" color="inherit" underline="none" sx={{ display: 'block', mb: 0.5 }}>About Us</Link>
            <Link href="/contact" color="inherit" underline="none" sx={{ display: 'block', mb: 0.5 }}>Contact</Link>
            <Link href="/booking" color="inherit" underline="none" sx={{ display: 'block' }}>Booking</Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Link href="https://www.facebook.com/profile.php?id=61561549854917" color="inherit" underline="none" sx={{ mx: 1 }}>
                <Facebook />
              </Link>
              <Link href="https://www.instagram.com/cleanslateautomotivedetailing/" color="inherit" underline="none" sx={{ mx: 1 }}>
                <Instagram />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          © 2024 CleanSlate Detailing. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
